import { ui } from "@clientio/rappid";
export class HaloService {
    contextElement;
    linkAddCallback;
    halo;
    graph;
    createHalo(node, paper, graph, linkAddCallback) {
        this.contextElement = node;
        this.graph = graph;
        this.linkAddCallback = linkAddCallback;
        // Render the halo
        if (this.halo) {
            this.halo.remove();
        }
        const cellView = paper.findViewByModel(node);
        const halo = new ui.Halo({
            cellView,
            boxContent: false,
            clearAll: false,
            clearOnBlankPointerdown: false,
            useModelGeometry: true,
            // bbox: () => {
            //     const bbox = cellView.getBBox()
            //     return bbox.scale(1.2, 1.2, bbox.center())
            // }
        });
        halo.removeHandle('resize');
        halo.removeHandle('rotate');
        halo.removeHandle('remove');
        halo.removeHandle('fork');
        halo.removeHandle('unlink');
        halo.removeHandle('clone');
        halo.removeHandle('direction'); // default on links
        if (linkAddCallback === null) {
            halo.removeHandle('link');
        }
        halo.on('action:link:add', link => {
            this.linkAddCallback(link);
        });
        this.halo = halo;
        halo.render();
        return halo;
    }
}
