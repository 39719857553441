import { renderMenu } from '@/components/Generic/Graph/services/contextMenuHelper';
export class ContextToolbarService {
    contextElement;
    createContextMenu(element, parentElement, lastX, lastY) {
        this.contextElement = element;
        const tools = [
            { action: 'showRelations', content: 'Include Related' },
            { action: 'addChild', content: 'Create Entity' },
            { action: 'remove', content: 'Remove from Diagram' },
            // {action: 'move', content: 'Move...'},
            // {action: 'copy', content: 'Copy...'},
            // {action: 'generateFN', content: 'Generate Requirements/Stories/Features'},
            // {action: 'generateQA', content: 'Generate Quality Attribute Requirements'},
        ];
        return renderMenu(tools, lastX, lastY);
    }
}
