/* eslint-disable */
/* eslint max-classes-per-file: 0 */
/* eslint class-methods-use-this: 0 */
/* eslint semi: 0 */
/* eslint comma-dangle: 0 */
import {
  dia,
  shapes,
  util,
  linkTools,
  elementTools,
  connectionStrategies
} from '@clientio/rappid'
import linkTypes from '@/components/Domain/ClassDiagram/services/linkTypes'

const COLORS = {
  header: '#a68e96',
  text: '#131e29',
  outline: '#131e29',
  main: '#fdecee',
  background: '#d7e2ea',
  grid: '#a1bbce',
  tools: '#fdecee',
}

const UNIT = 15
const MARGIN = 1 * UNIT
const RADIUS = UNIT / 2
// Header height = 4 * UNIT in total
const TYPE_HEIGHT = 1 // TYPE LABEL is removed
const NAME_HEIGHT = 18 + 22

export const shapeNamespace = { ...shapes }



class UMLLink extends shapes.standard.Link {
  defaults() {
    return util.defaultsDeep(
      {
        type: 'UMLLink',
        attrs: {
          root: {
            pointerEvents: 'visibleStroke',
          },
          line: {
            stroke: COLORS.outlineColor,
            targetMarker: {
              class: 'marker-uml-link',
              type: 'path',
              fill: 'none',
              stroke: COLORS.outlineColor,
              'stroke-width': 2,
              d: 'M 7 -4 0 0 7 4',
            },
          },
        },
        // router: orthogonalRouter,
      },
      super.defaults,
    )
  }
}

class Aggregation extends UMLLink {
  constructor(attrs, edge) {
    super({
      ...attrs, labels: createLabels([
        {
          content: edge?.rel_props?.multiplicity || '',
          type: 'multiplicity-target',
        },
      ]),
    })
  }

  defaults() {
    return util.defaultsDeep(
      {
        type: 'Aggregation',
        attrs: {
          line: {
            sourceMarker: {
              class: 'src-marker-agg-link',
              type: 'path',
              fill: '#161d31',
              'stroke-width': 1,
              d: 'M 10 -4 0 0 10 4 20 0 z',
              transform: 'scale(2)',
            },
          },
        },
      },
      super.defaults(),
    )
  }
}

class Composition extends UMLLink {
  defaults() {
    return util.defaultsDeep(
      {
        type: 'Composition',
        attrs: {
          line: {
            sourceMarker: {
              class: 'src-marker-comp-link',
              type: 'path',
              fill: COLORS.outlineColor,
              'stroke-width': 1,
              d: 'M 10 -4 0 0 10 4 20 0 z',
              transform: 'scale(2)',
            },
          },
        },
      },
      super.defaults(),
    )
  }
}

class Association extends UMLLink {
  constructor(attrs, edge) {
    super({
      ...attrs, labels: createLabels([
        {
          content: edge?.rel_props?.source_label,
          type: 'label-source',
        },
        {
          content: edge?.rel_props?.target_label,
          type: 'label-target',
        },
        {
          content: edge?.rel_props.name,
          type: 'label-midpoint',
        },
      ]),
    })
  }

  defaults() {
    return util.defaultsDeep(
      {
        type: 'Association',
        attrs: {
          line: {
            targetMarker: {
              class: 'src-marker-assc-link',
              type: 'path',
              fill: 'none',
              'stroke-width': 1,
              d: 'M -7 -4 0 0 -7 4',
              transform: 'scale(2)',
            },
          },
        },
      },
      super.defaults(),
    )
  }
}

class Dependency extends UMLLink {
  defaults() {
    return util.defaultsDeep(
      {
        type: 'Dependency',
        attrs: {
          line: {
            'stroke-dasharray': '10, 10',
            targetMarker: {
              class: 'src-marker-assc-link',
              type: 'path',
              fill: 'none',
              'stroke-width': 1,
              d: 'M -7 -4 0 0 -7 4',
              transform: 'scale(2)',
            },
          },
        },
      },
      super.defaults(),
    )
  }
}

class Inheritance extends UMLLink {
  defaults() {
    return util.defaultsDeep(
      {
        type: 'Inheritance',
        attrs: {
          line: {
            sourceMarker: {
              type: 'path',
              fill: COLORS.background,
              'stroke-width': 1,
              d: 'M 10 -4 0 0 10 4 z',
              transform: 'scale(2)',
            },
          },
        },
      },
      super.defaults(),
    )
  }
}

class Interface extends UMLLink {
  constructor(attrs, edge) {
    super({
      ...attrs, labels: createLabels([
        {
          content: edge?.rel_props?.label,
          type: 'label-midpoint',
        },
      ]),
    })
  }

  defaults() {
    return util.defaultsDeep(
      {
        type: 'Interface',
        attrs: {
          line: {
            sourceMarker: {
              type: 'path',
              fill: COLORS.background,
              'stroke-width': 1,
              d: 'M 10 -4 0 0 10 4 z',
              transform: 'scale(2)',
            },
          },
        },
      },
      super.defaults(),
    )
  }
}

const classes = {
  'Association': Association,
  'Aggregation': Aggregation,
  'Inheritance': Inheritance,
  'Interface': Interface,
  'Composition': Composition,
  'Dependency': Dependency,
}
const linkTypeClasses = linkTypes
  .map(lt => ({ ...lt, linkClass: classes[lt.name] }))

class SourceAnchorWithLabels extends linkTools.SourceAnchor {
  updateAnchor() {
    super.updateAnchor();
    updateLabelsTextAnchor(this.relatedView.model);
  }
}

class UML extends shapes.standard.Record {
  defaults() {
    return util.defaultsDeep(
      {
        thickness: 2,
        headerColor: COLORS.header,
        textColor: COLORS.text,
        outlineColor: COLORS.outline,
        color: COLORS.main,
        itemHeight: 2 * UNIT,
        itemOffset: 5,
        umlName: '',
        umlType: '',
        attrs: {
          root: {
            magnetSelector: 'body',
          },
          body: {
            width: 'calc(w)',
            height: 'calc(h)',
            stroke: '#000000',
            fill: '#FFFFFF',
            rx: RADIUS,
            ry: RADIUS,
          },
          header: {
            width: 'calc(w)',
            stroke: '#000000',
            fill: 'transparent',
          },
          umlNameLabel: {
            x: 'calc(0.5 * w)',
            fontFamily: 'sans-serif',
            textAnchor: 'middle',
            textVerticalAnchor: 'middle',
            fontSize: 18,
            fontWeight: 'bold',
            fill: COLORS.textColor,
          },
          itemLabel_attributesHeader: {
            fontFamily: 'sans-serif',
            fontStyle: 'italic',
            textAnchor: 'middle',
            x: 'calc(0.5 * w)',
            fontSize: 12,
          },
          itemLabel_operationsHeader: {
            fontFamily: 'sans-serif',
            fontStyle: 'italic',
            textAnchor: 'middle',
            x: 'calc(0.5 * w)',
            fontSize: 12,
          },
          itemLabels_static: {
            textDecoration: 'underline',
          },
          itemLabels: {
            fontFamily: 'sans-serif',
          },
        },
      },
      super.defaults,
    )
  }

  preinitialize(...args) {
    super.preinitialize(...args)
    this.markup = [
      {
        tagName: 'rect',
        selector: 'body',
      },
      {
        tagName: 'rect',
        selector: 'header',
      },
      {
        tagName: 'text',
        selector: 'umlNameLabel',
      },
    ]
  }

  buildHeader() {
    const {
      umlType,
      umlName,
      textColor,
      outlineColor,
      headerColor,
      thickness,
    } = this.attributes
    const fontSize = Math.max(28 - (umlName.length * 0.4), 8)
    return {
      header: {
        stroke: outlineColor,
        strokeWidth: thickness,
        height: TYPE_HEIGHT + NAME_HEIGHT,
        y: 0,
        fill: headerColor,
      },
      umlNameLabel: {
        y: TYPE_HEIGHT + NAME_HEIGHT / 2,
        text: umlName,
        fill: textColor,
        fontSize,
      },
    }
  }
}

class UMLComponent extends UML {
  defaults() {
    return {
      ...super.defaults(),
      type: 'UMLComponent',
      subComponents: [],
      ports: {
        groups: {
          subComponents: {
            position: {
              name: 'bottom',
            },
          },
        },
      },
    }
  }

  initialize(...args) {
    super.initialize(...args)
    this.buildShape()
  }

  buildShape(opt = {}) {
    const {
      subComponents,
      outlineColor,
      thickness,
      color,
      textColor,
    } = this.attributes

    this.removePorts()

    if (subComponents.length > 0) {
      subComponents.forEach(subComponent => {
        const {
          name,
          type,
          subheader,
        } = subComponent
        this.addPort(
          getPackagePort(name, type, subheader, color, outlineColor, thickness),
        )
      })
    }

    const headerAttrs = this.buildHeader()
    const padding = util.normalizeSides(this.get('padding'))

    this.set(
      {
        padding: {
          ...padding,
          top: TYPE_HEIGHT + NAME_HEIGHT,
        },
        attrs: util.defaultsDeep(
          {
            body: {
              stroke: outlineColor,
              strokeWidth: thickness,
              fill: color,
            },
            ...headerAttrs,
            itemLabels: {
              fill: textColor,
            },
            itemBody_delimiter: {
              fill: outlineColor,
            },
          },
          this.attr(),
        ),
      },
      opt,
    )
  }
}

class UMLClass extends UML {
  defaults() {
    return {
      ...super.defaults(),
      type: 'UMLClass',
      attributesHeader: '',
      operationsHeader: '',
    }
  }

  initialize(...args) {
    this.buildItems()
    super.initialize(...args)
  }

  buildItems(opt = {}) {
    const {
      attributesHeader,
      operationsHeader,
      color,
      outlineColor,
      textColor,
      attributes = [],
      operations = [],
      thickness,
    } = this.attributes

    const attributesItems = attributes.map((attribute, index) => {
      const {
        visibility = '+',
        name = '',
        type = '',
        isStatic = false,
      } = attribute
      const label =  `${name}: ${type}`
      this.attr(`itemLabel_attribute${index}/text`, label)
      return {
        id: `attribute${index}`,
        label,
        icon: this.getVisibilityIcon(visibility, textColor),
        group: isStatic ? 'static' : null,
      }
    })

    const operationsItems = operations.map((operation, index) => {
      const {
        visibility = '+',
        name = '',
        returnType = '',
        parameters = [],
        isStatic = false,
      } = operation

      const nameParams = parameters
        ? parameters.map(parameter => {
          const {
            name = '',
            returnType = '',
          } = parameter
          return `${name}: ${returnType}`
        })
        : []
      const label = `${name}(${nameParams.join(',')}): ${returnType}`
      this.attr(`itemLabel_operation${index}/text`, label)
      return {
        id: `operation${index}`,
        label,
        icon: this.getVisibilityIcon(visibility, textColor),
        group: isStatic ? 'static' : null,
      }
    })

    const items = []

    if (attributesHeader) {
      items.push({
        id: 'attributesHeader',
        label: { text: attributesHeader },
      })
      this.attr('itemLabel_attributesHeader/text', attributesHeader)
    }

    items.push(...attributesItems)

    if (attributesItems.length > 0 && operationsItems.length > 0) {
      items.push({
        id: 'delimiter',
        height: thickness,
        label: '',
      })
    }

    if (operationsHeader) {
      items.push({
        id: 'operationsHeader',
        label: operationsHeader,
      })
      this.attr('itemLabel_operationsHeader/text', operationsHeader)
    }

    items.push(...operationsItems)

    const headerAttrs = this.buildHeader()
    const padding = util.normalizeSides(this.get('padding'))

    const newAttrs = util.defaultsDeep(
      {
        body: {
          stroke: outlineColor,
          strokeWidth: thickness,
          fill: color,
          class: 'class-body-background'
        },
        ...headerAttrs,
        itemLabels: {
          fill: textColor,
          class: 'class-label-text'
        },
        itemBody_delimiter: {
          fill: outlineColor,
          class: 'class-delimiter'
        },
      },
      this.attr(),
    )
    this.set(
      {
        padding: {
          ...padding,
          top: TYPE_HEIGHT + NAME_HEIGHT,
        },
        attrs: newAttrs,
        items: [items],
      },
      opt,
    )
  }

  getVisibilityIcon(visibility, color) {
    const d = {
      '+': 'M 8 0 V 16 M 0 8 H 16',
      '-': 'M 0 8 H 16',
      '#': 'M 5 0 3 16 M 0 5 H 16 M 12 0 10 16 M 0 11 H 16',
      '~': 'M 0 8 A 4 4 1 1 1 8 8 A 4 4 1 1 0 16 8',
      '/': 'M 12 0 L 4 16',
    }[visibility]
    return `data:image/svg+xml;utf8,${encodeURIComponent(`<svg                                
                version='1.1'
                viewBox='-3 -3 22 22'
            >
                <path d='${d}' stroke='${color}' stroke-width='2' fill='none'/>
            </svg>`)}`
  }
}

// Enable UML elements and links to be recreated from JSON
// Test: graph.fromJSON(graph.toJSON())
Object.assign(shapeNamespace, {
  UMLClass,
  UMLComponent,
  Composition,
  Aggregation,
})

// functions

// Snap the anchor to the grid
// and to the center of the element if it's close enough.
function snapAnchorToGrid(coords, endView) {
  coords.snapToGrid(UNIT)
  const bbox = endView.model.getBBox()
  // Find the closest point on the bbox border.
  return bbox.pointNearestToPoint(coords)
}

function getAbsoluteAnchor(coords, view, magnet) {
  // Calculate the anchor offset from the magnet's top-left corner.
  return connectionStrategies.pinAbsolute({}, view, magnet, coords).anchor
}

function getPackagePort(name, type, subheader, color, outlineColor, thickness) {
  return {
    group: 'subComponents',
    label: {
      position: {
        name: 'bottom',
      },
      markup: [
        {
          tagName: 'text',
          selector: 'name',
        },
        {
          tagName: 'text',
          selector: 'subheader',
        },
      ],
    },
    attrs: {
      body: {
        width: 24,
        height: 24,
        x: -12,
        y: -6,
        stroke: outlineColor,
        fill: color,
        strokeWidth: thickness,
      },
      name: {
        text: `${name}: ${type}`,
        fill: COLORS.textColor,
        y: 16,
        fontSize: 12,
        fontFamily: 'sans-serif',
      },
      subheader: {
        text: `${subheader}`,
        fill: COLORS.textColor,
        y: 28,
        fontSize: 12,
        fontFamily: 'sans-serif',
      },
    },
    markup: [
      {
        tagName: 'rect',
        selector: 'body',
      },
    ],
  }
}

function getTextAnchor(side) {
  return side === 'left' || side === 'bottom' ? 'end' : 'start'
}

function createLabels(comments) {
  return comments.filter(comment => comment.content).map(comment => {
    const {
      type,
      content,
    } = comment
    const [commentType, position] = type.split('-')

    const isSource = position === 'source'
    const isMidPoint = position === 'midpoint'
    const isLabel = commentType === 'label'

    return {
      attrs: {
        text: {
          text: content,
          fontSize: 14,
          fill: COLORS.textColor,
          fontFamily: 'sans-serif',
          textVerticalAnchor: 'middle',
          pointerEvents: 'none',
          // textAnchor is set in `updateLabelsTextAnchor()`
        },
        rect: {
          fill: COLORS.background,
          class: 'class-link-label-background'
        },
      },
      position: {
        distance: isMidPoint ? 0.5 : (isSource ? MARGIN * 0.5 * content.length: -MARGIN * 0.5 * content.length),
        offset: UNIT * (isLabel ? 1 : -1),
        args: {
          keepGradient: true,
          ensureLegibility: true,
        },
      },
    }
  })
}

export function createBlockShape(name, members, methods) {
  const block = new UMLClass({
    size: { width: 280 },
    position: {
      x: 80,
      y: 300,
    },
    padding: { bottom: 2 * MARGIN },
    umlName: 'Block',
    umlType: 'block',
    attributesHeader: 'members',
    attributes: [
      {
        name: 'prevBlockHash',
        visibility: '-',
        type: 'Block',
      },
    ],
    operationsHeader: 'methods',
    operations: [
      { // example of how to specify a method with a return type
        name: 'nonce',
        returnType: 'Real',
      },
    ],
  })
}

export function createClassNode(node, properties = []) {
  const nodeBlock = new UMLClass({
    size: { width: Math.max(node.name.length * 4, 280) },
    position: {
      x: 100,
      y: 100,
    },
    padding: { bottom: 2 * MARGIN },
    umlName: node.name,
    umlType: 'block',
    attributesHeader: '',
    attributes: properties,
    id: node.id,
  })
  nodeBlock.attr('body/data-testid', node.name+'CdCell')
  if (node.abstract) {
    nodeBlock.attr('umlNameLabel/fontStyle', 'italic')
  }
  return nodeBlock
}

export function createLink(edge) {
  if (!(edge.source && edge.target)) {
    return null;
  }
  const linkTypeClass = linkTypeClasses
    .find(ltc => ltc.relType.in === edge.rel_type
      || ltc.relType.out === edge.rel_type)
  if (linkTypeClass) {
    const attrs = {
      source: {
        id: edge.rel_type === linkTypeClass.relType.in ? edge.target : edge.source,
      },
      target: {
        id: edge.rel_type === linkTypeClass.relType.in ? edge.source : edge.target,
      },
    }
    return new linkTypeClass.linkClass(attrs, edge)
  }
  return null
}

function updateLabelsTextAnchor(link) {
  const labels = util.cloneDeep(link.labels()).map(label => {
    let anchorDef;
    let element;
    if (label.position.distance < 0) {
      element = link.getTargetCell();
      anchorDef = link.target().anchor;
    } else {
      element = link.getSourceCell();
      anchorDef = link.source().anchor;
    }
    const bbox = element.getBBox();
    const { name = 'topLeft', args = {} } = anchorDef;
    const anchorName = util.toKebabCase(name);
    const anchorOffset = { x: args.dx, y: args.dy };
    const anchor = util
      .getRectPoint(bbox, anchorName)
      .offset(anchorOffset);
    label.attrs.text.textAnchor = getTextAnchor(
      bbox.sideNearestToPoint(anchor)
    );
    return label;
  });
  link.labels(labels);
}


// a fallback router if avoidRouter stops
function orthogonalRouter(vertices, opt, linkView) {
  var sourceBBox = linkView.sourceBBox;
  var targetBBox = linkView.targetBBox;
  var sourcePoint = linkView.sourceAnchor;
  var targetPoint = linkView.targetAnchor;
  const { x: tx0, y: ty0 } = targetBBox;
  const { x: sx0, y: sy0 } = sourceBBox;
  const sourceOutsidePoint = sourcePoint.clone();
  const spacing = 56; // size of the endpoint diamonds
  const sourceSide = sourceBBox.sideNearestToPoint(sourcePoint);
  switch (sourceSide) {
    case "left":
      sourceOutsidePoint.x = sx0 - spacing;
      break;
    case "right":
      sourceOutsidePoint.x = sx0 + sourceBBox.width + spacing;
      break;
    case "top":
      sourceOutsidePoint.y = sy0 - spacing;
      break;
    case "bottom":
      sourceOutsidePoint.y = sy0 + sourceBBox.height + spacing;
      break;
  }
  const targetOutsidePoint = targetPoint.clone();
  const targetSide = targetBBox.sideNearestToPoint(targetPoint);
  switch (targetSide) {
    case "left":
      targetOutsidePoint.x = targetBBox.x - spacing;
      break;
    case "right":
      targetOutsidePoint.x = targetBBox.x + targetBBox.width + spacing;
      break;
    case "top":
      targetOutsidePoint.y = targetBBox.y - spacing;
      break;
    case "bottom":
      targetOutsidePoint.y = targetBBox.y + targetBBox.height + spacing;
      break;
  }

  const { x: sox, y: soy } = sourceOutsidePoint;
  const { x: tox, y: toy } = targetOutsidePoint;
  const tx1 = tx0 + targetBBox.width;
  const ty1 = ty0 + targetBBox.height;
  const tcx = (tx0 + tx1) / 2;
  const tcy = (ty0 + ty1) / 2;
  const sx1 = sx0 + sourceBBox.width;
  const sy1 = sy0 + sourceBBox.height;

  if (sourceSide === "left" && targetSide === "right") {
    if (sox < tox) {
      let y = (soy + toy) / 2;
      if (sox < tx0) {
        if (y > tcy && y < ty1 + spacing) {
          y = ty0 - spacing;
        } else if (y <= tcy && y > ty0 - spacing) {
          y = ty1 + spacing;
        }
      }
      return [
        { x: sox, y: soy },
        { x: sox, y },
        { x: tox, y },
        { x: tox, y: toy }
      ];
    } else {
      const x = (sox + tox) / 2;
      return [
        { x, y: soy },
        { x, y: toy }
      ];
    }
  } else if (sourceSide === "right" && targetSide === "left") {
    // Right to left
    if (sox > tox) {
      let y = (soy + toy) / 2;
      if (sox > tx1) {
        if (y > tcy && y < ty1 + spacing) {
          y = ty0 - spacing;
        } else if (y <= tcy && y > ty0 - spacing) {
          y = ty1 + spacing;
        }
      }
      return [
        { x: sox, y: soy },
        { x: sox, y },
        { x: tox, y },
        { x: tox, y: toy }
      ];
    } else {
      const x = (sox + tox) / 2;
      return [
        { x, y: soy },
        { x, y: toy }
      ];
    }
  } else if (sourceSide === "top" && targetSide === "bottom") {
    // analogical to let to right
    if (soy < toy) {
      let x = (sox + tox) / 2;
      if (soy < ty0) {
        if (x > tcx && x < tx1 + spacing) {
          x = tx0 - spacing;
        } else if (x <= tcx && x > tx0 - spacing) {
          x = tx1 + spacing;
        }
      }
      return [
        { x: sox, y: soy },
        { x, y: soy },
        { x, y: toy },
        { x: tox, y: toy }
      ];
    }
    const y = (soy + toy) / 2;
    return [
      { x: sox, y },
      { x: tox, y }
    ];
  } else if (sourceSide === "bottom" && targetSide === "top") {
    // analogical to right to left
    if (soy >= toy) {
      let x = (sox + tox) / 2;
      if (soy > ty1) {
        if (x > tcx && x < tx1 + spacing) {
          x = tx0 - spacing;
        } else if (x <= tcx && x > tx0 - spacing) {
          x = tx1 + spacing;
        }
      }
      return [
        { x: sox, y: soy },
        { x, y: soy },
        { x, y: toy },
        { x: tox, y: toy }
      ];
    }
    const y = (soy + toy) / 2;
    return [
      { x: sox, y },
      { x: tox, y }
    ];
  } else if (sourceSide === "top" && targetSide === "top") {
    const y = Math.min(soy, toy);
    return [
      { x: sox, y },
      { x: tox, y }
    ];
  } else if (sourceSide === "bottom" && targetSide === "bottom") {
    const y = Math.max(soy, toy);
    return [
      { x: sox, y },
      { x: tox, y }
    ];
  } else if (sourceSide === "left" && targetSide === "left") {
    const x = Math.min(sox, tox);
    return [
      { x, y: soy },
      { x, y: toy }
    ];
  } else if (sourceSide === "right" && targetSide === "right") {
    const x = Math.max(sox, tox);
    return [
      { x, y: soy },
      { x, y: toy }
    ];
  } else if (sourceSide === "top" && targetSide === "right") {
    if (soy > toy) {
      if (sox < tox) {
        let y = (sy0 + toy) / 2;
        if (y > tcy && y < ty1 + spacing && sox < tx0 - spacing) {
          y = ty0 - spacing;
        }
        return [
          { x: sox, y },
          { x: tox, y },
          { x: tox, y: toy }
        ];
      }
      return [{ x: sox, y: toy }];
    }
    const x = (sx0 + tox) / 2;
    if (x > sx0 - spacing && soy < ty1) {
      const y = Math.min(sy0, ty0) - spacing;
      const x = Math.max(sx1, tx1) + spacing;
      return [
        { x: sox, y },
        { x, y },
        { x, y: toy }
      ];
    }
    return [
      { x: sox, y: soy },
      { x: x, y: soy },
      { x: x, y: toy }
    ];
  } else if (sourceSide === "top" && targetSide === "left") {
    if (soy > toy) {
      if (sox > tox) {
        let y = (sy0 + toy) / 2;
        if (y > tcy && y < ty1 + spacing && sox > tx1 + spacing) {
          y = ty0 - spacing;
        }
        return [
          { x: sox, y },
          { x: tox, y },
          { x: tox, y: toy }
        ];
      }
      return [{ x: sox, y: toy }];
    }
    const x = (sx1 + tox) / 2;
    if (x < sx1 + spacing && soy < ty1) {
      const y = Math.min(sy0, ty0) - spacing;
      const x = Math.min(sx0, tx0) - spacing;
      return [
        { x: sox, y },
        { x, y },
        { x, y: toy }
      ];
    }
    return [
      { x: sox, y: soy },
      { x: x, y: soy },
      { x: x, y: toy }
    ];
  } else if (sourceSide === "bottom" && targetSide === "right") {
    if (soy < toy) {
      if (sox < tox) {
        let y = (sy1 + ty0) / 2;
        if (y < tcy && y > ty0 - spacing && sox < tx0 - spacing) {
          y = ty1 + spacing;
        }
        return [
          { x: sox, y },
          { x: tox, y },
          { x: tox, y: toy }
        ];
      }
      return [
        { x: sox, y: soy },
        { x: sox, y: toy },
        { x: tox, y: toy }
      ];
    }
    const x = (sx0 + tox) / 2;
    if (x > sx0 - spacing && sy1 > toy) {
      const y = Math.max(sy1, ty1) + spacing;
      const x = Math.max(sx1, tx1) + spacing;
      return [
        { x: sox, y },
        { x, y },
        { x, y: toy }
      ];
    }
    return [
      { x: sox, y: soy },
      { x: x, y: soy },
      { x: x, y: toy },
      { x: tox, y: toy }
    ];
  } else if (sourceSide === "bottom" && targetSide === "left") {
    if (soy < toy) {
      if (sox > tox) {
        let y = (sy1 + ty0) / 2;
        if (y < tcy && y > ty0 - spacing && sox > tx1 + spacing) {
          y = ty1 + spacing;
        }
        return [
          { x: sox, y },
          { x: tox, y },
          { x: tox, y: toy }
        ];
      }
      return [
        { x: sox, y: soy },
        { x: sox, y: toy },
        { x: tox, y: toy }
      ];
    }
    const x = (sx1 + tox) / 2;
    if (x < sx1 + spacing && sy1 > toy) {
      const y = Math.max(sy1, ty1) + spacing;
      const x = Math.min(sx0, tx0) - spacing;
      return [
        { x: sox, y },
        { x, y },
        { x, y: toy }
      ];
    }
    return [
      { x: sox, y: soy },
      { x: x, y: soy },
      { x: x, y: toy },
      { x: tox, y: toy }
    ];
  } else if (sourceSide === "left" && targetSide === "bottom") {
    if (sox > tox) {
      if (soy < toy) {
        let x = (sx0 + tx1) / 2;
        if (x > tcx && x < tx1 + spacing && soy < ty0 - spacing) {
          x = Math.max(sx1, tx1) + spacing;
        }
        return [
          { x, y: soy },
          { x, y: toy },
          { x: tox, y: toy }
        ];
      }
      return [{ x: tox, y: soy }];
    }
    const y = (sy0 + ty1) / 2;
    if (y > sy0 - spacing) {
      const x = Math.min(sx0, tx0) - spacing;
      const y = Math.max(sy1, ty1) + spacing;
      return [
        { x, y: soy },
        { x, y },
        { x: tox, y }
      ];
    }
    return [
      { x: sox, y: soy },
      { x: sox, y: y },
      { x: tox, y },
      { x: tox, y: toy }
    ];
  } else if (sourceSide === "left" && targetSide === "top") {
    // Analogy to the left - bottom case.
    if (sox > tox) {
      if (soy > toy) {
        let x = (sx0 + tx1) / 2;
        if (x > tcx && x < tx1 + spacing && soy > ty1 + spacing) {
          x = Math.max(sx1, tx1) + spacing;
        }
        return [
          { x, y: soy },
          { x, y: toy },
          { x: tox, y: toy }
        ];
      }
      return [{ x: tox, y: soy }];
    }
    const y = (sy1 + ty0) / 2;
    if (y < sy1 + spacing) {
      const x = Math.min(sx0, tx0) - spacing;
      const y = Math.min(sy0, ty0) - spacing;
      return [
        { x, y: soy },
        { x, y },
        { x: tox, y }
      ];
    }
    return [
      { x: sox, y: soy },
      { x: sox, y: y },
      { x: tox, y },
      { x: tox, y: toy }
    ];
  } else if (sourceSide === "right" && targetSide === "top") {
    // Analogy to the right - bottom case.
    if (sox < tox) {
      if (soy > toy) {
        let x = (sx1 + tx0) / 2;
        if (x < tcx && x > tx0 - spacing && soy > ty1 + spacing) {
          x = Math.max(sx1, tx1) + spacing;
        }
        return [
          { x, y: soy },
          { x, y: toy },
          { x: tox, y: toy }
        ];
      }
      return [{ x: tox, y: soy }];
    }
    const y = (sy1 + ty0) / 2;
    if (y < sy1 + spacing) {
      const x = Math.max(sx1, tx1) + spacing;
      const y = Math.min(sy0, ty0) - spacing;
      return [
        { x, y: soy },
        { x, y },
        { x: tox, y }
      ];
    }
    return [
      { x: sox, y: soy },
      { x: sox, y: y },
      { x: tox, y },
      { x: tox, y: toy }
    ];
  } else if (sourceSide === "right" && targetSide === "bottom") {
    // Analogy to the right - top case.
    if (sox < tox) {
      if (soy < toy) {
        let x = (sx1 + tx0) / 2;
        if (x < tcx && x > tx0 - spacing && soy < ty0 - spacing) {
          x = Math.min(sx0, tx0) - spacing;
        }
        return [
          { x, y: soy },
          { x, y: toy },
          { x: tox, y: toy }
        ];
      }
      return [
        { x: sox, y: soy },
        { x: tox, y: soy },
        { x: tox, y: toy }
      ];
    }
    const y = (sy0 + ty1) / 2;
    if (y > sy0 - spacing) {
      const x = Math.max(sx1, tx1) + spacing;
      const y = Math.max(sy1, ty1) + spacing;
      return [
        { x, y: soy },
        { x, y },
        { x: tox, y }
      ];
    }
    return [
      { x: sox, y: soy },
      { x: sox, y: y },
      { x: tox, y },
      { x: tox, y: toy }
    ];
  }
  return [];
}

