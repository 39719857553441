<template>
  <div class="entity-browser-container">
    <class-diagram-entity-browser-tree :expanded="true" :fetch-children="fetchChildrenFn" :treeNode="node" @addNode="addNode">
      <template v-slot="np">
        <slot :node="np.node"></slot>
      </template>
    </class-diagram-entity-browser-tree>
<!--    <ScrollToTopButton />-->
  </div>
</template>
<script>
import { ref } from '@vue/composition-api'
import store from '@/store'
import ClassDiagramEntityBrowserTree from '@/components/Domain/ClassDiagram/ClassDiagramEntityBrowserTree.vue'
import coreService from '@/libs/api-services/core-service'
import { useRouter } from '@core/utils/utils'

export default {
  name: 'ClassDiagramEntityBrowser',
  components: { ClassDiagramEntityBrowserTree },
  props: ['rootNode'],
  setup(props, context) {
    const { route, router } = useRouter()
    const node = ref(props.rootNode)
    if (!node.value) {
      const root = store.state.model.lookup.ProblemSpace
      node.value = { id: root, name: 'Root', childCount: 1 }
    }
    async function fetchChildrenFn(cptId) {
      const { modelId } = route.value.params
      const result = await coreService.classDiagramApi.expand(cptId, true)
      return result.nodes
    }
    function addNode(node) {
      context.emit('addEntity', node)
    }
    return {
      node,
      fetchChildrenFn,
      addNode,
    }
  },
}
</script>
<style scoped lang="scss">
  .entity-browser-container {
    max-height: 100%;
    overflow-y: auto;
  }
</style>
