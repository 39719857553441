import AddEntityModal from '@/components/Domain/Modals/AddEntity.vue';
import GenerateRequirementsFn from '@/components/Domain/Modals/GenerateRequirementsFn.vue';
import AllocateFnModal from '@/components/Domain/Modals/AllocateFn.vue';
import ShowComponentModal from '@/components/Domain/Modals/Context/ShowComponentModal.vue';
import axiosIns from '@/libs/axios';
import Ripple from 'vue-ripple-directive';
import { useRouter } from '@core/utils/utils';
import { computed, nextTick, onMounted, onUnmounted, ref, watch, } from '@vue/composition-api';
import store from '@/store';
import { AvoidRouter } from '@/components/Domain/ClassDiagram/services/avoidRouter';
import ClassDiagramEntityBrowser from '@/components/Domain/ClassDiagram/ClassDiagramEntityBrowser.vue';
import { useJointJs } from '@/components/Generic/Graph/useJointJs';
import { util, layout, } from '@clientio/rappid';
import FuseSearchBox from '@core/components/fuse-search-box/FuseSearchBox.vue';
import coreService from '@/libs/api-services/core-service';
import EntityDetails from '@/components/Domain/EntityDetails.vue';
import { ContextToolbarService } from '@/components/Domain/ClassDiagram/services/contextToolbarService';
import { HaloService } from '@/components/Domain/ClassDiagram/services/haloService';
import linkTypes from '@/components/Domain/ClassDiagram/services/linkTypes';
import { createLink, createClassNode, } from './services/shapes';
export default {
    name: 'ClassDiagramJoint',
    components: {
        EntityDetails,
        FuseSearchBox,
        AddEntityModal,
        GenerateRequirementsFn,
        AllocateFnModal,
        ShowComponentModal,
        ClassDiagramEntityBrowser,
    },
    setup(props, context) {
        const { route, router } = useRouter();
        const routeParams = computed(() => route.value.params);
        const routeQuery = computed(() => route.value.query);
        const canvas = ref(null); // Element ref
        const canvasClass = ref('canvas');
        const isGraphLoadingStatus = ref(false);
        const hiddenNodeIds = ref([]);
        const diagramData = ref(null);
        const nodeMap = ref({});
        const nodes = ref([]);
        // searchList combines already loaded nodes with the results of a db search query
        const searchList = ref([]);
        const searchText = ref('');
        const isSidebarVisible = ref(false);
        const isEntityBrowserVisible = ref(false);
        const showEntityBrowserOnBlank = ref(false);
        const links = ref([]);
        const { graph, paper, nav, scroller, selection, keyboard, bulkSelectedNodes, exporters, } = useJointJs(canvas, { paper: { interactive: { linkMove: false } } });
        const linkTypesVisible = linkTypes.map(lt => ({ ...lt, visible: ref(true) }));
        const haloService = new HaloService();
        const selectedEntity = computed(() => (bulkSelectedNodes.value?.length > 0 ? bulkSelectedNodes.value[0] : null));
        const selectedEntity2 = computed(() => store.state.domainModel.selected_entity2);
        let positionMap = {};
        async function moveNodes(id, parentId, isInheritance) {
            await store.dispatch('domainModel/moveComponent', {
                id,
                parent_id: parentId,
                parent_rel_type: isInheritance ? 'inheritance' : 'aggregation',
            });
            isGraphLoadingStatus.value = false;
        }
        async function copyNodes(id, parentId, isInheritance) {
            const { data } = await axiosIns.post('/api/v2/domain_model/copy_component', {
                cpt: id,
                parent: parentId,
                parent_rel_type: isInheritance ? 'inheritance' : 'aggregation',
            }, { params: { model: store.state.model.id } });
            await store.dispatch('selectEntity2', data.id);
            isGraphLoadingStatus.value = false;
        }
        // In-place refresh of the view of a single node when edited
        function updateNode(entity) {
            const id = entity?.context?.details?.id;
            const cell = graph.getCell(id);
            entity.context.details.labels = entity.context.labels;
            const node = transformNode(entity.context.details);
            cell.attr('bodyTextContent/title', node.display_name);
            cell.attr('bodyTextContent/html', util.sanitizeHTML(node.display_name));
            cell.attr('header/text', node.type);
        }
        // Watch for the data of the same selectedEntity2 changing
        // Usually from edits in the sidebar
        watch(() => selectedEntity2.value, (newNode, oldNode) => {
            if (oldNode && oldNode?.context?.details) {
                if (newNode && newNode?.context?.details) {
                    if (newNode.context.details.id === oldNode.context.details.id) {
                        updateNode(newNode);
                    }
                }
            }
        });
        watch(() => routeQuery.value, newParams => {
            if (diagramData.value.nodes.length < 1) {
                reloadGraph();
            }
            focusOnRouteNode();
        });
        function transformNode(rawNode) {
            const x = rawNode;
            x.type = rawNode.type || 'Unknown';
            if (x.labels) {
                x.type = x.labels.filter(l => l !== 'Component').join();
            }
            x.tags = [];
            if (x.abstract && x.abstract === 'True') {
                x.tags.push('abstract');
            }
            else {
                x.tags.push('not_abstract');
            }
            x.tags.push(x.validity);
            if (x.labels && (x.labels.includes('Function') || x.labels.includes('Capability'))) {
                x.tags.push('fn');
            }
            else {
                x.tags.push('nfn');
            }
            if (x.parent_rel && x.parent_rel === 'inheritance') {
                x.link_label = 'sub-type';
            }
            else {
                x.link_label = x.multiplicity;
            }
            x.display_name = x.acronym && x.acronym !== '' ? `${x.name} (${x.acronym})` : x.name;
            return x;
        }
        // Create the nodes with their data and links between them
        async function createGraph() {
            const treeData = diagramData.value;
            const nn = [];
            const filteredEdges = treeData.edges.filter(e => {
                const msn = treeData.nodes.find(sn => e.source === sn.id);
                const mdn = treeData.nodes.find(dn => e.target === dn.id);
                if (!msn) {
                    console.log('source node not available for edge', e);
                    return false;
                }
                if (!mdn) {
                    console.log('target node not available for edge', e);
                    return false;
                }
                return isLinkTypeVisible(e.rel_type);
            });
            const targets = filteredEdges.map(e => e.target);
            treeData.nodes.forEach(x => {
                const node = transformNode(x);
                node._childCount = 0;
                node._children = [];
                nodeMap.value[node.id] = node;
                // add root
                if (!targets.includes(node.id)) {
                    nn.unshift(node);
                }
                else {
                    nn.push(node);
                }
            });
            const allLinks = filteredEdges.map(e => createLink(e));
            links.value = allLinks;
            nodes.value = nn;
        }
        async function loadTree() {
            isGraphLoadingStatus.value = true;
            let result = null;
            if (routeParams.value.diagramId) {
                result = await coreService.classDiagramApi.get(routeParams.value.diagramId);
            }
            else if (routeParams.value.root) {
                result = await coreService.classDiagramApi.getClassDiagramFromRoot(routeParams.value.root);
            }
            diagramData.value = result;
            searchList.value = diagramData.value.nodes;
            isGraphLoadingStatus.value = false;
            await focusOnRouteNode();
        }
        async function reloadFocus() {
            await redrawGraph();
        }
        function createShape(n) {
            const allAttributes = [...n.properties, ...n.attributes].map(cn => ({
                name: cn.name,
                visibility: '-',
                type: cn.type,
            }));
            const shape = createClassNode(n, allAttributes);
            shape.on('change', () => {
                if (shape.hasChanged('position')) {
                    positionMap[n.id] = shape.get('position');
                }
            });
            return shape;
        }
        async function redrawGraph() {
            isGraphLoadingStatus.value = true;
            await createGraph();
            const hidden = hiddenNodeIds.value;
            const cells = nodes.value
                .filter(n => !hidden?.find(c => c === n.id))
                .map(createShape);
            paper.freeze();
            graph.resetCells([...links.value, ...cells]);
            await layoutTree();
            paper.unfreeze();
            try {
                cells.forEach(c => {
                    const node = nodes.value.find(n => n.id === c.id);
                    if (node && (node.x || node.y)) {
                        c.set({ position: { x: node.x, y: node.y } });
                    }
                });
            }
            catch (e) {
                console.log('Couldn\'t layout, no unhidden cells loaded');
            }
            graph.resetCells([...cells, ...links.value]);
            await focusOnRouteNode();
            isGraphLoadingStatus.value = false;
        }
        let avoidRouter = null;
        async function layoutTree(ignorePositions = false) {
            const graphLayout = new layout.TreeLayout({
                filter: children => children.filter(c => {
                    if (ignorePositions)
                        return true;
                    // treelayout should only affect nodes that have not been manually positioned by the user
                    const node = nodes.value.find(n => n.id === c.id);
                    return !(node.x && node.y);
                }),
                graph,
                parentGap: 120,
                siblingGap: 120,
                direction: 'B',
            });
            graphLayout.layout();
            try {
                if (!avoidRouter) {
                    await AvoidRouter.load();
                    avoidRouter = new AvoidRouter(graph, {
                        shapeBufferDistance: 20,
                        idealNudgingDistance: 20, // the distance between parallel links
                        portOverflow: 12,
                    });
                }
                else {
                    avoidRouter.removeGraphListeners();
                }
                avoidRouter.addGraphListeners();
                avoidRouter.routeAll();
            }
            catch (e) {
                console.error('Error using avoidLib to layout diagram', e);
            }
        }
        async function reloadGraph() {
            await loadTree();
            await redrawGraph();
        }
        async function refreshClicked() {
            await reloadGraph();
        }
        let autosaveHandle = null;
        async function autosave() {
            const positionList = Object.keys(positionMap).map(id => {
                const p = positionMap[id];
                return { id, x: p.x, y: p.y };
            });
            if (positionList.length > 0) {
                const positions = {
                    positions: positionList,
                };
                if (routeParams.value.diagramId) {
                    await coreService.classDiagramApi.update(routeParams.value.diagramId, positions);
                    positionMap = {};
                }
            }
        }
        onMounted(async () => {
            // do we need to append here if the useJointJs is already doing this?
            // ...or is this an override?
            canvas.value.appendChild(scroller.el);
            await reloadGraph();
            scroller.center();
            paper.unfreeze();
            paper.scale(0.85);
            scroller.zoomToFit();
            await focusOnRouteNode();
            if (diagramData.value && diagramData.value.diagram) {
                store.commit('app/SET_DYNAMIC_PAGE_TITLE', diagramData.value.diagram.name);
            }
            autosaveHandle = setInterval(autosave, 5000);
        });
        onUnmounted(() => {
            clearInterval(autosaveHandle);
            keyboard.off('ctrl+/');
            keyboard.off('ctrl+d');
            keyboard.off('ctrl+a');
        });
        const contextToolbarService = new ContextToolbarService();
        let parentElement;
        // Context menu event
        paper.on('cell:contextmenu', triggerContextMenu);
        paper.on('cell:pointerclick', (cellView, evt, x, y) => {
            if (cellView.model.isLink()) {
                return;
            }
            focusNodeById(cellView.model.id, false);
            updateUrlFocus(cellView.model.id);
            context.emit('sidebar', true);
            isSidebarVisible.value = true;
            isEntityBrowserVisible.value = false;
        });
        paper.on('blank:pointerup', event => {
            const evt = event;
            // If any nodes were selected using the drag
            if (selection.collection.models.length > 0 && evt.originalEvent.ctrlKey) {
                if (!evt.shiftKey) {
                    bulkSelectedNodes.value = [];
                }
                selection.collection.models.forEach(cell => {
                    bulkSelectedNodes.value.push(cell.id);
                });
                bulkSelectedNodes.value = [...new Set(bulkSelectedNodes.value)];
                if (evt.shiftKey) {
                    // UNION mode (add bulk to existing bulk selection)
                    selection.collection.reset(graph.getElements().filter(e => bulkSelectedNodes.value.includes(e.id)));
                }
                if (selection.collection.models.length === 1) {
                    context.emit('sidebar', true);
                    isSidebarVisible.value = true;
                    isEntityBrowserVisible.value = false;
                }
            }
            else {
                context.emit('sidebar', false);
                isSidebarVisible.value = false;
                if (showEntityBrowserOnBlank.value) {
                    isEntityBrowserVisible.value = true;
                }
            }
        });
        paper.on('cell:pointerdown', (elementView, evt) => {
            evt.preventDefault();
        });
        keyboard.on('ctrl+/', evt => {
            evt.preventDefault();
            nextTick(() => {
                try {
                    const ele = document.querySelector('#bn-search-dropdown .vs__search');
                    ele.focus();
                    // throws exceptions even though it works
                    // eslint-disable-next-line no-empty
                }
                catch (e) {
                    console.error(e);
                }
            });
        });
        function triggerContextMenu(cellView, evt) {
            if (cellView.model.isLink())
                return;
            // eslint-disable-next-line prefer-destructuring
            if (!graph.isSource(cellView.model))
                parentElement = graph.getPredecessors(cellView.model)[0];
            else
                parentElement = null;
            // Render the menu
            const contextToolbar = contextToolbarService.createContextMenu(cellView.model, parentElement, evt.clientX, evt.clientY);
            handleContextActions(contextToolbar);
            document.querySelector('.joint-context-toolbar').addEventListener('contextmenu', evt => {
                // Because JointJS only prevents default on paper elements, it doesn't apply to the context menu that happens
                // to be right under your mouse when you right-click, which just sets off the default event :)
                evt.preventDefault();
            });
        }
        function handleContextActions(contextToolbar) {
            const node = contextToolbarService.contextElement;
            contextToolbar.on('action:addChild', async (_) => {
                contextToolbar.remove();
                await store.dispatch('domainModel/selectEntity2', node.id);
                context.root.$bvModal.show('add-entity-modal');
            });
            contextToolbar.on('action:remove', async (_) => {
                const opts = {
                    title: 'Remove from diagram?', centered: true, okTitle: 'Remove', okVariant: 'danger',
                };
                const shouldRemove = await context.root.$bvModal.msgBoxConfirm(`Are you sure you want to remove '${nodes.value.find(n => n.id === node.id).name}' from the diagram?`, opts);
                contextToolbar.remove();
                if (shouldRemove) {
                    const result = await coreService.classDiagramApi.removeNode(String(node.id), routeParams.value.diagramId, routeParams.value.modelId);
                    graph.getConnectedLinks(node).forEach(l => l.remove());
                    node.remove();
                    nodes.value = nodes.value.filter(n => n.id !== node.id);
                }
            });
            contextToolbar.on('action:move', async (_) => {
                contextToolbar.remove();
                await store.dispatch('domainModel/selectEntity2', node.id);
                context.root.$bvModal.show('move-entity-modal');
            });
            contextToolbar.on('action:copy', async (_) => {
                contextToolbar.remove();
                await store.dispatch('domainModel/selectEntity2', node.id);
                context.root.$bvModal.show('copy-entity-modal');
            });
            contextToolbar.on('action:generateQA', async (_) => {
                contextToolbar.remove();
                await store.dispatch('domainModel/selectEntity2', node.id);
                context.root.$bvModal.show('generate-qa-requirements-modal');
            });
            contextToolbar.on('action:generateFN', async (_) => {
                contextToolbar.remove();
                await store.dispatch('domainModel/selectEntity2', node.id);
                context.root.$bvModal.show('generate-fn-requirements-modal');
            });
            contextToolbar.on('action:showRelations', async (_) => {
                contextToolbar.remove();
                const result = await coreService.classDiagramApi.expand(String(node.id), false);
                result.nodes.forEach(addNode);
            });
        }
        /// Update the focus in the URL silently, should not actually focus the node
        function updateUrlFocus(id) {
            const { href } = router.resolve({
                name: `${route.value.name}`,
                params: { ...routeParams.value },
                query: { focus: id },
            });
            routeQuery.value.focus = id;
            window.history.pushState({}, null, href);
        }
        async function focusOnRouteNode() {
            const nodeId = routeQuery.value.focus;
            if (nodeId) {
                await focusNodeById(nodeId);
            }
        }
        async function focusNodeById(id, zoomTo = true) {
            const element = graph.getCell(id);
            if (element) {
                if (id !== selectedEntity2.value) {
                    store.dispatch('domainModel/selectEntity2', id);
                    selection.collection.reset([element]);
                    bulkSelectedNodes.value = [element];
                    haloService.createHalo(element, paper, graph, onLinkAdd);
                }
                if (zoomTo) {
                    scroller.scrollToElement(element, { animation: { duration: 600 } });
                }
            }
        }
        async function doSearch(text) {
            const results = await coreService.classDiagramApi.find(text, route.value.params.modelId);
            const unloadedResults = results.nodes.filter(r => !nodes.value.find(n => n.id === r.id))
                .map(r => {
                r.icon = 'DatabaseIcon';
                return r;
            });
            searchList.value = [...nodes.value, ...unloadedResults];
            nextTick(() => {
                try {
                    const ele = document.querySelector('#bn-search-dropdown .vs__search');
                    ele.focus();
                    // throws exceptions even though it works
                    // eslint-disable-next-line no-empty
                }
                catch (e) {
                    console.error(e);
                }
            });
        }
        /// If the result has an icon, it needs to be loaded from the db
        function onSearchResultClicked(result) {
            if (result.icon) {
                const c = graph.getCell(result.id);
                if (!c) {
                    addNode(result);
                }
            }
            else {
                updateUrlFocus(result.id);
                focusNodeById(result.id);
            }
        }
        function showGenerateFNRequirements() {
            context.root.$bvModal.show('generate-fn-requirements-modal');
        }
        function showGenerateQARequirements() {
            context.root.$bvModal.show('generate-qa-requirements-modal');
        }
        function isNodeLoaded(nodeId) {
            return nodes.value.find(n => n.id === nodeId);
        }
        function isLinkTypeVisible(linkRelType) {
            return linkTypesVisible.filter(lt => lt.visible.value === true)
                .find(lt => lt.relType.in === linkRelType || lt.relType.out === linkRelType);
        }
        function isLinkInvalid(link) {
            // Checks if links should not have Views added...
            if (link.target === link.source) {
                // ...links to itself
                return true;
            }
            if (!isLinkTypeVisible(link.rel_type)) {
                // ...user doesn't want to see this type
                return true;
            }
            if (links.value.find(l => link.source === l.source
                && link.target === l.target
                && link.rel_type === l.get('rel_type'))) {
                // ...link already exists (simple check)
                return true;
            }
            if (links.value.find(l => link.source === l.source
                && link.target === l.target
                && linkTypes.find(lt => lt.relType.in === link.rel_type)?.relType.out === l.get('rel_type'))) {
                // some links have multiple edges with different relTypes in different directions depending on the node
                // that they have been loaded from.
                return true;
            }
            return false;
        }
        function addNode(node, point = null) {
            // nodes from search results should include edges (links)
            if (!graph.getCell(node.id)) {
                const cell = createShape(transformNode(node));
                if (point) {
                    cell.set('position', point);
                }
                nodes.value.push(node);
                // Both the source and target should now be loaded because the new node just got pushed
                const links = node.edges.filter(e => !isLinkInvalid(e) && isNodeLoaded(e.source) && isNodeLoaded(e.target)).map(createLink);
                graph.addCell(cell);
                graph.addCells(links);
            }
        }
        async function onComponentAdded(componentId) {
            const results = await coreService.classDiagramApi.getComponentForClassDiagram(componentId, routeParams.value.modelId);
            addNode(results.nodes[0]);
        }
        async function addNodeFromBrowser(node) {
            if (!graph.getCell(node.id)) {
                const result = await coreService.classDiagramApi.getComponentForClassDiagram(node.id, routeParams.value.modelId);
                addNode(result.nodes[0]);
                await layoutTree();
                focusNodeById(node.id, true);
            }
            else {
                focusNodeById(node.id);
            }
        }
        async function onNodeDropped(ev) {
            ev.preventDefault();
            const nodeId = ev.dataTransfer.getData('text/plain');
            const result = await coreService.classDiagramApi.getComponentForClassDiagram(nodeId, routeParams.value.modelId);
            addNode(result.nodes[0], paper.clientToLocalPoint(ev.clientX, ev.clientY));
        }
        function onNodeDragOver(ev) {
            ev.preventDefault(ev);
            ev.dataTransfer.dropEffect = 'link';
        }
        async function onLinkAdd(link) {
            const src = link.get('source');
            const tgt = link.get('target');
            if (!src.id || !tgt.id || src.id === tgt.id) {
                link.remove();
                return;
            }
            const payload = {
                model: routeParams.value.modelId,
                name: 'Associated with',
                source: src.id,
                source_multiplicity: '1',
                source_label: '',
                target: tgt.id,
                target_multiplicity: '*',
                target_label: '',
                rel_id: '',
            };
            const result = await axiosIns.post('/api/v2/domain_model/comp_rels', payload);
            if (result) {
                await reloadGraph();
            }
            link.remove();
        }
        function onLinkToggleClicked(linkTypeVisible) {
            linkTypeVisible.visible.value = !linkTypeVisible.visible.value;
            reloadGraph();
        }
        function onNodeUpdated() {
            reloadGraph();
        }
        const component = {
            addNodeFromBrowser,
            canvas,
            canvasClass,
            diagramData,
            doSearch,
            exportPdf: exporters.pdf,
            focusNodeById,
            isEntityBrowserVisible,
            isGraphLoadingStatus,
            isNodeLoaded,
            isSidebarVisible,
            layoutTree,
            linkTypesVisible,
            newNode: {},
            nodeMap,
            nodes,
            onComponentAdded,
            onLinkToggleClicked,
            onNodeDragOver,
            onNodeDropped,
            onNodeUpdated,
            onSearchResultClicked,
            refreshClicked,
            reloadFocus,
            searchList,
            searchText,
            selectedEntity,
            selectedEntity2,
            showEntityBrowserOnBlank,
            showGenerateFNRequirements,
            showGenerateQARequirements,
        };
        return component;
    },
    directives: {
        Ripple,
    },
    props: {
        updateObject: {
            type: Object,
            default: null,
        },
    },
};
