import { computed, onMounted, onUnmounted, ref, } from '@vue/composition-api';
export default {
    name: 'ClassDiagramEntityBrowserTree',
    props: ['treeNode', 'fetchChildren', 'expanded'],
    setup(props, context) {
        const node = ref(props.treeNode);
        node.value.children = ref(null);
        node.value.expanded = ref(false);
        const fetchChildrenFn = props.fetchChildren;
        const filterText = ref('');
        const showToolbar = ref(false);
        const isLoading = ref(false);
        const dragHandle = ref(null);
        const dragStartHandler = ev => {
            ev.dataTransfer.setData('text/plain', node.value.id);
        };
        onMounted(async () => {
            if (props.expanded) {
                await _loadChildren();
            }
            if (dragHandle.value) {
                dragHandle.value.addEventListener('dragstart', dragStartHandler);
            }
        });
        onUnmounted(() => {
            if (dragHandle.value) {
                dragHandle.value.removeEventListener('dragstart', dragStartHandler);
            }
        });
        async function _loadChildren() {
            if (node.value.id && node.value.children.value === null) {
                isLoading.value = true;
                const result = await props.fetchChildren(node.value.id);
                result.sort((a, b) => a.name.localeCompare(b.name));
                node.value.children.value = result;
                isLoading.value = false;
            }
        }
        function toggleExpansion() {
            node.value.expanded.value = !node.value.expanded.value;
            _loadChildren();
        }
        function addNode(node) {
            context.emit('addNode', node);
        }
        const filteredNodes = computed(() => node?.value?.children?.value?.filter(n => filterNode(n)) || []);
        function filterNode(n) {
            if (filterText.value.length > 0) {
                return n.name.toLocaleLowerCase().includes(filterText.value.toLocaleLowerCase());
            }
            return true;
        }
        return {
            node,
            fetchChildrenFn,
            toggleExpansion,
            addNode,
            startExpanded: props.expanded,
            filteredNodes,
            filterText,
            showToolbar,
            isLoading,
            dragHandle,
        };
    },
};
